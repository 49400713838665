<template>
  <BreadCrumb ImageUrl="" PageName="JCLの沿革"/>
  <section class="history-one">
            <div class="container">
                <div class="block-title text-center">
                    <p class="color-2"><span>Our History</span></p>
                </div>
                <!-- /.block-title text-center -->
                <div class="history-one__row-wrap">
                    <div class="history-one__row-circle"></div>
                    <!-- /.history-one__row-circle -->
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="history-one__box wow fadeInLeft" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-name: fadeInLeft;" data-aos="fade-right" :data-aos-duration="500">
                                <div class="history-one__image">
                                    <img src="assets/images/resources/history-1-1.jpg" alt="">
                                </div>
                                <!-- /.history-one__image -->
                                <div class="history-one__content">
                                    <h3>株式会社ジェーシーエル設立</h3>
                                    <p>埼玉県さいたま市大宮区桜木町にJCL設立、資本金1000万円、オフショア開発開始。
                                    </p>
                                </div>
                                <!-- /.history-one__content -->
                            </div>
                            <!-- /.history-one__box -->
                        </div>
                        <!-- /.col-lg-6 -->
                        <div class="col-lg-6 d-flex">
                            <div class="my-auto">
                                <div class="history-one__date">
                                    <h3>2020年04月</h3>
                                </div>
                                <!-- /.history-one__date -->
                            </div>
                            <!-- /.my-auto -->
                        </div>
                        <!-- /.col-lg-6 d-flex -->
                    </div>
                    <!-- /.row -->
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="history-one__box wow fadeInRight" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-name: fadeInRight;" data-aos="fade-right" :data-aos-duration="500">
                                <div class="history-one__image">
                                    <img src="assets/images/resources/history-1-2.jpg" alt="">
                                </div>
                                <!-- /.history-one__image -->
                                <div class="history-one__content">
                                    <h3>ソリューション事業部を設立</h3>
                                    <p>第一期目決算：5,200万円
                                    </p>
                                </div>
                                <!-- /.history-one__content -->
                            </div>
                            <!-- /.history-one__box -->
                        </div>
                        <!-- /.col-lg-6 -->
                        <div class="col-lg-6 d-flex">
                            <div class="my-auto">
                                <div class="history-one__date">
                                    <h3>2021年04月</h3>
                                </div>
                                <!-- /.history-one__date -->
                            </div>
                            <!-- /.my-auto -->
                        </div>
                        <!-- /.col-lg-6 d-flex -->
                    </div>
                    <!-- /.row -->
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="history-one__box wow fadeInLeft" data-wow-duration="1500ms" style="visibility: hidden; animation-duration: 1500ms; animation-name: none;" data-aos="fade-right" :data-aos-duration="500">
                                <div class="history-one__image">
                                    <img src="assets/images/resources/history-1-3.jpg?t=112" alt="">
                                </div>
                                <!-- /.history-one__image -->
                                <div class="history-one__content">
                                    <h3>Salesforce事業部を設立</h3>
                                    <p>第二期目決算：2.29億円
                                    </p>
                                </div>
                                <!-- /.history-one__content -->
                            </div>
                            <!-- /.history-one__box -->
                        </div>
                        <!-- /.col-lg-6 -->
                        <div class="col-lg-6 d-flex">
                            <div class="my-auto">
                                <div class="history-one__date">
                                    <h3>2022年04月</h3>
                                </div>
                                <!-- /.history-one__date -->
                            </div>
                            <!-- /.my-auto -->
                        </div>
                        <!-- /.col-lg-6 d-flex -->
                    </div>
                    <!-- /.row -->
                </div>
                <!-- /.history-one__row-wrap -->
            </div>
            <!-- /.container -->
        </section>
</template>

<script>
import BreadCrumb from "../components/BreadCrumb";
export default {
  name: "News",
  components:{ BreadCrumb },
  setup() {
    
  }
}
</script>

<style scoped>

</style>
