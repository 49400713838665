import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Contact from "@/views/Contact";
import About from "@/views/About";
import Service from "@/views/Service";
import Access from "@/views/Access";
import News from "@/views/News";
import NewsDetail from "@/views/NewsDetail";
import Recruit from "@/views/Recruit";
import ServiceDetail from "@/views/ServiceDetail";
import Business from "@/views/Business";
import Policy from "@/views/Policy";
import Greeting from "@/views/Greeting";
import Strength from "@/views/Strength";
import Philosophy from "@/views/Philosophy";
import History from "@/views/History";

const routes = [{
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            pathType: 'home',
            title: 'ホーム',
            content: {
                keywords: '『株式会社ジェーシーエルの個人情報保護マネジメントシステム』を制定するにあたり、会社の『個人情報保護方針』を定め、これを実行し、維持して参ります',
                description: '株式会社ジェーシーエル'
            }
        }
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact,
        meta: {
            pathType: 'contact',
            title: 'お問い合わせ',
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    {
        path: '/about',
        name: 'about',
        component: About,
        meta: {
            pathType: 'about',
            title: '会社概要',
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    {
        path: '/service',
        name: 'service',
        component: Service,
        meta: {
            pathType: 'service',
            title: '事業内容',
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    {
        path: '/access',
        name: 'access',
        component: Access,
        meta: {
            pathType: 'access',
            title: 'アクセス',
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    {
        path: '/news',
        name: 'news',
        component: News,
        meta: {
            pathType: 'news',
            title: 'ニュース',
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    {
        path: '/news/:id',
        name: 'newsDetail',
        component: NewsDetail,
        meta: {
            pathType: 'newsDetail',
            title: 'ニュース詳細',
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    {
        path: '/serviceDetail/:id',
        name: 'serviceDetail',
        component: ServiceDetail,
        meta: {
            pathType: 'serviceDetail',
            title: 'サービス詳細',
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    {
        path: '/recruit',
        name: 'recruit',
        component: Recruit,
        meta: {
            pathType: 'recruit',
            title: '採用情報',
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    {
        path: '/business',
        name: 'business',
        component: Business,
        meta: {
            pathType: 'business',
            title: '事業内容',
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    {
        path: '/policy',
        name: 'policy',
        component: Policy,
        meta: {
            pathType: 'policy',
            title: '個人情報保護方針',
            content: {
                keywords: '',
                description: 'JCL - 個人情報保護方針'
            }
        }
    },
    {
        path: '/greeting',
        name: 'greeting',
        component: Greeting,
        meta: {
            pathType: 'greeting',
            title: '社長挨拶',
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    {
        path: '/strength',
        name: 'strength',
        component: Strength,
        meta: {
            pathType: 'strength',
            title: 'JCLの強み',
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    {
        path: '/philosophy',
        name: 'philosophy',
        component: Philosophy,
        meta: {
            pathType: 'philosophy',
            title: '経営理念',
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    {
        path: '/history',
        name: 'history',
        component: History,
        meta: {
            pathType: 'history',
            title: 'JCLの沿革',
            content: {
                keywords: '',
                description: ''
            }
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

/*
 * title 要素を制御する
 */
router.beforeEach((to, from, next) => {
    if (to.meta.content) {
        let head = document.getElementsByTagName('head');
        let meta = document.createElement('meta');
        document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
        meta.content = to.meta.content;
        head[0].appendChild(meta)
    }
    document.title = to.meta.title + ' - JCL'
    next()
})
export default router