<template>
  <!-- Start JCL breadcrumb section -->
  <BreadCrumb ImageUrl="/assets/images/shapes/header-bg-3.png" PageName="会社概要"/>
  <!-- End JCL breadcrumb section -->
  <section class="service-one">
    <div class="container" style="
    background-image: url(/assets/images/about-1.png);
    background-repeat: no-repeat;
    background-position: right -21px center;
    background-size: 70%;
">

        <div class="block-title text-center">
            <p><span>About JCL</span></p>
        </div>
        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-list-alt"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px">
                <h4>会社名</h4>
                <p>{{HomeInfo.CompanyName}}{{HomeInfo.CompanyNo}}</p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-building"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px">
                <h4>住所</h4>
                <p>
                  〒{{HomeInfo.PostCode}} {{HomeInfo.Address}} {{ HomeInfo.Building }}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-phone" style="background-image: linear-gradient(-45deg, rgb(64, 158, 255) 0%, rgb(255 64 197) 100%);"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>代表電話番号</h4>
                <p>
                  <span v-if="HomeInfo.Fax" >TEL. </span><a :href="'tel:'+HomeInfo.Tel">{{HomeInfo.Tel}}</a>
                  <span v-if="HomeInfo.Fax" >&emsp;FAX. {{HomeInfo.Fax}}</span>
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        
        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-envelope-open" style="background-image: linear-gradient(-45deg, #409eff 0%, rgb(235, 255, 64) 100%)"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>E-mail</h4>
                <p>
                  <a :href="'mailto:'+HomeInfo.Email">{{HomeInfo.Email}}</a>
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>

        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-user-circle"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px">
                <h4>代表者名 (CEO)</h4>
                <p>
                  {{HomeInfo.Representative}}<span v-if="HomeInfo.Katakana">（{{HomeInfo.Katakana}}）</span>
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single" v-if="HomeInfo.CTO!=''">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-users" style="background-image: linear-gradient(-45deg, #88b0ff 0%, #2098d1 100%);"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px">
                <h4>役員</h4>
                <p>
                  {{HomeInfo.CTO}}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-jpy" style="background-image: linear-gradient(-45deg, rgb(220, 136, 255) 0%, rgb(253 255 53) 100%)"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>資本金</h4>
                <p>
                  {{formatPrice(this.HomeInfo.Capital)}}万円
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-calendar-check-o" style="background-image: linear-gradient(-45deg, rgb(220, 136, 255) 0%, #409eff 100%);"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>設立</h4>
                <p>
                  {{HomeInfo.CreateDate}}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single"  v-if="HomeInfo.Employees!=''">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-address-card" style="linear-gradient(-45deg, #d7ff40 0%, #28a745 100%);"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px">
                <h4>従業員(人数)</h4>
                <p>
                  {{HomeInfo.Employees}}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-university" style="background-image: linear-gradient(-45deg, rgb(255 186 136) 0%, rgb(235 255 64) 100%)"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>主要取引銀行</h4>
                <p>
                  {{HomeInfo.Banks}}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single" v-if="HomeInfo.YearSell!=''">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-line-chart" style="background-image: linear-gradient(-45deg, rgb(236 16 34) 0%, rgb(235, 255, 64) 100%);"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>売上</h4>
                <p>
                  {{HomeInfo.YearSell}}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single" v-if="HomeInfo.HakenNo!=''">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-gift" style="background-image: linear-gradient(-45deg, rgb(236 16 34) 0%, rgb(235, 255, 64) 100%);"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>労働者派遣事業許可番号</h4>
                <p>
                  {{HomeInfo.HakenNo}}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>

    </div>
    <!-- /.container -->
</section>
</template>

<script>
import {ref} from "vue";
import BreadCrumb from "../components/BreadCrumb";
export default {
  name: "About",
  components:{ BreadCrumb },
  setup() {
      const HomeInfo = ref({})
      $.ajax({
        type: "GET",
        url: "/api/company.php",
        dataType: "json",
        // crossDomain: true,
        beforeSend: function(xhr){
             xhr.withCredentials = true;
             xhr.setRequestHeader('Access-Control-Allow-Origin','*');
             xhr.setRequestHeader('Access-Control-Allow-Credentials', 'true');
        },
        async: false,
        success: (res) => {
            HomeInfo.value = res;
        },
        error: (res) => {
           HomeInfo.value =   {
                Tel: '03-5050-2712',
                Fax: '',
                PostCode: '330-9501',
                Email: 'service@jcltk.com',
                Address: '埼玉県さいたま市大宮区桜木町2丁目3番地',
                Building: '大宮マルイ 7階',
                CompanyName: '株式会社ジェーシーエル',
                CompanyNo: '（略称：JCL）',
                Representative: '張　建厚',
                Katakana: 'チュウ　ケンコウ',
                Capital: '1000',
                Banks: '三菱UFJ銀行',
                CreateDate: '2020年04月01日',
                YearSell: '2022年04月　二期目決算2.29億円',
                HakenNo: '', // '派13-○○○○○○'
                CTO: '',
                Employees:'25',
                //<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1406.6569637960001!2d139.62157875227183!3d35.906611639238555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018c1d6eabbc35b%3A0x2e923ce55d1f0076!2z5qCq5byP5Lya56S-44K444Kn44O844K344O844Ko44Or!5e0!3m2!1sja!2sjp!4v1656845420181!5m2!1sja!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                MapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1406.6569637960001!2d139.62157875227183!3d35.906611639238555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018c1d6eabbc35b%3A0x2e923ce55d1f0076!2z5qCq5byP5Lya56S-44K444Kn44O844K344O844Ko44Or!5e0!3m2!1sja!2sjp!4v1656845420181!5m2!1sja!2sjp'
            }
        }
     })
     return {HomeInfo}
  },
  methods: {
    formatPrice(value) {
        return (value||'1000').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
}
</script>
