<template>
<header>
    <nav class="main-nav-one stricky original" id="original-fix">
        <div class="container">
            <div class="inner-container">
                <div class="logo-box">
                    <router-link to="/">
                        <img src="/assets/images/logo-1-1.png" alt="" id="logo-menu">
                    </router-link>
                    <a href="#" class="side-menu__toggler" id="fa-bars"><i class="fa fa-bars"></i></a>
                </div>
                <!-- /.logo-box -->
                <div class="main-nav__main-navigation">
                    <ul class="main-nav__navigation-box">
                        <li :class="{'current':pathType==='home'}" id="home_current">
                            <router-link to="/">ホーム</router-link>
                        </li>
                        <li :class="{'current':pathType==='about'}" id="about_current">
                            <router-link to="/about">会社情報</router-link>
                        </li>
                        <li :class="{'current':pathType==='business'}" id="business_current">
                            <router-link to="/business">事業内容</router-link>
                        </li>
                        <li :class="{'current':pathType==='service'}" id="service_current">
                            <router-link to="/service">サービス</router-link>
                        </li>
                        <li :class="{'current':pathType==='news'}" id="news_current">
                            <router-link to="/news">お知らせ</router-link>
                        </li>
                        <li :class="{'current':pathType==='access'}" id="access_current">
                            <router-link to="/access">アクセス</router-link>
                        </li>
                        <li :class="{'current':pathType==='recruit'}" id="recruit_current">
                            <router-link to="/recruit">採用情報</router-link>
                        </li>
                        <li :class="{'current':pathType==='contact'}" id="contact_current">
                            <router-link to="/contact">お問い合わせ</router-link>
                        </li>
                    </ul>
                    <!-- /.main-nav__navigation-box -->
                </div>
                <!-- /.main-nav__main-navigation -->
                <div class="main-nav__right">
                     <a href="http://file.jcltk.com" class="search-popup__toggler main-nav__search" onclick="location.href = 'https://file.jcltk.com'"  id="fa-user-circle"><i class="far fa-user-circle"></i></a>
                    <router-link to="/contact" class="thm-btn main-nav-one__btn"><span>お問い合わせ</span></router-link>
                    <!-- /.thm-btn main-nav-one__btn -->
                </div>
                <!-- /.main-nav__right -->
            </div>
            <!-- /.inner-container -->
        </div>
        <!-- /.container-fluid -->
    </nav>
</header>
<!--side-menu-->
<div class="side-menu__block">
    <a href="#" class="side-menu__toggler side-menu__close-btn"><i class="fa fa-times"></i>
        <!-- /.fa fa-close --></a>
    <div class="side-menu__block-overlay custom-cursor__overlay">
        <div class="cursor"></div>
        <div class="cursor-follower"></div>
    </div>
    <!-- /.side-menu__block-overlay -->
   
    <div class="side-menu__block-inner">
        <nav class="mobile-nav__container" style="width:100%;">
            <!-- content is loading via js -->
        </nav>
    </div>
<!-- /.side-menu__block-inner -->
</div>
</template>

<script>
import { onMounted,ref,watch } from '@vue/runtime-core'
import { useRoute} from "vue-router"
export default {
  name: "Header",
  setup() { 
    const route = useRoute();
    const pathType = ref('')
    const ww = Window.innerWidth;
    
    watch(
      () => route.meta.pathType,
      newPathType => {
        pathType.value = newPathType;
      }
    )
    onMounted(()=>{
       pathType.value = route.meta.pathType || location.pathname.substring(1);
       if(window.innerWidth < 900) {
          $('#'+ pathType.value  + '_current').addClass('current');
       }
    })
    return {pathType,ww}
  }
}
</script>