import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import ElementUI from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
AOS.init({
    offset: 20,
    delay: 300, // values from 0 to 3000, with step 50ms
    duration: 800 // values from 0 to 3000, with step 50ms
});
let HomeInfo = {
    Tel: '03-5050-2712',
    Fax: '',
    PostCode: '330-9501',
    Email: 'jcl.kanri@jcltk.com',
    Address: '埼玉県さいたま市大宮区桜木町2丁目3番地',
    Building: '大宮マルイ 7階',
    CompanyName: '株式会社ジェーシーエル',
    CompanyNo: '（略称：JCL）',
    Representative: '張　建厚',
    Katakana: 'チュウ　ケンコウ',
    Capital: '1000',
    Banks: '三菱UFJ銀行',
    CreateDate: '2020年04月01日',
    YearSell: '2022年04月　二期目決算2.29億円',
    HakenNo: '', // '派13-○○○○○○'
    CTO: '',
    //<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1406.6569637960001!2d139.62157875227183!3d35.906611639238555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018c1d6eabbc35b%3A0x2e923ce55d1f0076!2z5qCq5byP5Lya56S-44K444Kn44O844K344O844Ko44Or!5e0!3m2!1sja!2sjp!4v1656845420181!5m2!1sja!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    MapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1406.6569637960001!2d139.62157875227183!3d35.906611639238555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018c1d6eabbc35b%3A0x2e923ce55d1f0076!2z5qCq5byP5Lya56S-44K444Kn44O844K344O844Ko44Or!5e0!3m2!1sja!2sjp!4v1656845420181!5m2!1sja!2sjp'
}
const app = createApp(App)
$.ajax({
    type: "GET",
    url: "/api/company.php",
    dataType: "json",
    success: (res) => {
        HomeInfo = res
        app.config.globalProperties.HomeInfo = res
        app.use(router).use(ElementUI).use(HomeInfo).use(AOS).mount('#app')
    },
    error: (res) => {
        app.config.globalProperties.HomeInfo = HomeInfo
        app.use(router).use(ElementUI).use(HomeInfo).use(AOS).mount('#app')
    },
});