<template>
    <!-- Start JCL breadcrumb section -->
  <BreadCrumb ImageUrl="/assets/images/shapes/header-bg-4.png" PageName="採用情報"/>
  <!-- End JCL breadcrumb section -->
   <!--
    <img src="/assets/images/shapes/bg-shape-1-2.png" class="error-404__bg-shape-2" alt="">
    <img src="/assets/images/shapes/bg-shape-1-3.png" class="error-404__bg-shape-3" alt="">
    -->

  <div class="error-404__bubble-1"></div>
  <!-- /.error-404__bubble-1 -->
  <div class="error-404__bubble-2"></div>
  <!-- /.error-404__bubble-2 -->
  <div class="error-404__bubble-3"></div>
  <!-- /.error-404__bubble-3 -->
  <div class="error-404__bubble-4"></div>
  <!-- /.error-404__bubble-4 -->
  <div class="error-404__bubble-5"></div>
  <!-- /.error-404__bubble-5 -->
  <div class="error-404__bubble-6"></div>
  <!-- /.error-404__bubble-6 -->
  <div class="error-404__bubble-7"></div>
  <!-- /.error-404__bubble-7 -->
  <div class="error-404__bubble-8"></div>
  <!-- End reinfo_breadcrumb section -->
  <!-- Start reinfo_contact section -->
  <section class="auninfo_contact cantact_v2 section_padding" style="margin-top:50px">
    <div class="container">
       <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
            <p><span>Recruit</span></p>
        </div>
        <div class="jcl_recruit_body common_body" data-aos="fade-right">
          <div>
            <el-row :gutter="24">
              <el-col :span="12">
                <div class="jcl_recruit_top_menu_body" :class="{active:active===0}" @click="active=0">
                  <div>
                    <h6 class="jcl_recruit_h6_big pt10 tc" style="padding-top:30px">新卒</h6>
                    <h6 class="jcl_recruit_h6_sm tc pb10">New Grad</h6>
                    <el-row :gutter="24" class="mt20 jcl_recruit_top_menu_row">
                      <el-col :span="12" class="sm_no_show">
                        <img class="jcl_recruit_top_menu_img" src="/assets/images/recruit1.png" />
                      </el-col>
                      <el-col :span="12" class="sm_no_show">
                        <p class="jcl_recruit_top_menu_text">JCLでは、システムエンジニアを目指す方を求めています。全学部・全学科からの募集で、社員の半数以上が文系出身、プログラミング未経験者です。ITのスペシャリストへ成長できる環境をご用意して、お待ちしています。</p>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="jcl_recruit_top_menu_body" :class="{active:active===1}" @click="active=1">
                  <div>
                    <h6 class="jcl_recruit_h6_big pt10 tc" style="padding-top:30px">中途</h6>
                    <h6 class="jcl_recruit_h6_sm tc pb10">Mid Career</h6>
                    <el-row :gutter="24" class="mt20 jcl_recruit_top_menu_row">
                      <el-col :span="12" class="sm_no_show">
                        <img class="jcl_recruit_top_menu_img" src="/assets/images/recruit0.png" />
                      </el-col>
                      <el-col :span="12" class="sm_no_show">
                        <p class="jcl_recruit_top_menu_text">JCLでは、システムエンジニア、プログラマーの経験者を求めています。既にお持ちの技術や経験を是非弊社で活かしてみませんか？働きながら技術の向上を図りたいという向上心のある方のご応募をお待ちしております。</p>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="jcl_recruit_content_body" v-show="active===0">
            <div>
              <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
                  <p><span>New Graduates</span></p>
                  <h4>新卒採用情報</h4>
             </div>
              <div>
                <table class="jcl_recruit_table" data-aos="fade-right">
                  <tbody>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>募集職種</div></td>
                      <td><div>システムエンジニア</div></td>
                    </tr>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>募集人数</div></td>
                      <td><div>東京10名</div></td>
                    </tr>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>募集学科</div></td>
                      <td>
                        <div>
                          全学部・全学科<br />
                          ★社員の半数以上が文系出身・プログラミング未経験者です
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>資格</div></td>
                      <td>
                        <div>新卒{{(new Date().getFullYear())}}年3月卒業見込みの方<br /> 既卒 卒業後3年以内の方</div>
                      </td>
                    </tr>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>初任給</div></td>
                      <td><div>大学院：213,000円<br />
                                四大卒：206,000円<br />
                                専門卒：181,200円（2年の場合）</div></td>
                    </tr>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>募集職種</div></td>
                      <td><div>システムエンジニア</div></td>
                    </tr>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>諸手当</div></td>
                      <td><div>通勤手当、時間外手当、出張手当、家族手当、資格手当、役職手当など</div></td>
                    </tr>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>昇給</div></td>
                      <td><div>年1回（4月）</div></td>
                    </tr>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>賞与</div></td>
                      <td><div>年2回（7月、1月）</div></td>
                    </tr>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>福利厚生</div></td>
                      <td><div>厚生年金保険、雇用保険、労災保険<br />
                        確定拠出年金制度(選択制)<br />
                                永年勤続報奨制度 等々</div></td>
                    </tr>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>休日・休暇</div></td>
                      <td><div>完全週休二日制(土、日、祝日)<br />
                                年末年始休暇(12月30日～1月3日)<br />
                                年次有給休暇<br />
                                育児休暇<br />
                                介護休暇<br />
                                当社規程により特別休暇（慶弔）など</div></td>
                    </tr>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>勤務地</div></td>
                      <td><div>原則 採用拠点(東京)での 勤務となります<br />
                                ※案件により取引先企業にて、開発業務を行う場合もあります<br />
                                主な常駐エリア<br />
                              ・東京：本社及び首都圏(東京・神奈川)</div></td>
                    </tr>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>勤務時間</div></td>
                      <td><div>東京 9：30～18：30（実働 8時間）</div></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
              <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500" style="margin-top:50px;">
            <p><span>Recruit Step</span></p>
            <h4>新卒採用先行ステップ</h4>
          </div>
            <div class="mt30">
              <div class="jcl_recruit_step_body">
                <div class="jcl_recruit_step_body">
                  <div data-aos="fade-right">
                    <div class="jcl_recruit_step_title">STEP.1 エントリー</div>
                    <div class="jcl_recruit_step_content">以下マイナビサイトよりエントリーお願いします。</div>
                  </div>
                  <div class="tc f20"><i class="el-icon-caret-bottom"></i></div>
                  <div data-aos="fade-right">
                    <div class="jcl_recruit_step_title">STEP.2 会社説明会</div>
                    <div class="jcl_recruit_step_content">会社説明：会社の概略をご説明いたします。<br />仕事説明：システム業界の仕事を具体的にご説明します。</div>
                  </div>
                  <div class="tc f20"><i class="el-icon-caret-bottom"></i></div>
                  <div data-aos="fade-right">
                    <div class="jcl_recruit_step_title">STEP.3 面接試験</div>
                    <div class="jcl_recruit_step_content">採用担当との面接試験。履歴書やエントリーシートの確認が中心の個人面接となります。</div>
                  </div>
                  <div class="tc f20"><i class="el-icon-caret-bottom"></i></div>
                  <div data-aos="fade-right">
                    <div class="jcl_recruit_step_title">STEP.4 最終面接</div>
                    <div class="jcl_recruit_step_content">開発部門責任者との面接試験。志望動機、将来像等の質問が中心となります。</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="jcl_recruit_content_body" v-show="active===1">
            <div>
            <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
                  <p><span>Mid Career Hire</span></p>
                  <h4>キャリア採用情報</h4>
             </div>
              <div data-aos="fade-right">
                <table class="jcl_recruit_table">
                  <tbody>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>雇用形態</div></td>
                      <td><div>正社員</div></td>
                    </tr>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>募集職種</div></td>
                      <td><div>システムエンジニア、リーダー、プログラマー</div></td>
                    </tr>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>職務内容</div></td>
                      <td>
                        <div>
                          ①Java、PHP、.Net、Python、Nodejs、PL/SQL、Dynamics 365等を使用したシステムの設計・開発<br />
                          ②プロジェクトマネージメント
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>必要な経験</div></td>
                      <td>
                        <div>①Java、PHP、.Net、Python、Nodejs、PL/SQL、Dynamics 365<br />
                        ②各種DB設計(Oracle、SQL Server、DB2、MySQL)</div>
                      </td>
                    </tr>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>望ましい経験</div></td>
                      <td><div>プロジェクトリーダー経験 Android、iPhone、iPad 開発経験 フレームワーク経験</div></td>
                    </tr>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>学歴</div></td>
                      <td><div>不問</div></td>
                    </tr>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>勤務地</div></td>
                      <td><div>東京本社、その他顧客先（都内各地）</div></td>
                    </tr>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>勤務時間</div></td>
                      <td><div>①弊社事業所の場合／9:30～18:30
                        <br />②お客様事業所の場合／お客様環境による</div></td>
                    </tr>
                    <tr>
                      <td class="jcl_recruit_title_td"><div>面接場所</div></td>
                      <td><div>東京本社<br />
                           〒{{HomeInfo.PostCode}} {{HomeInfo.Address}} {{ HomeInfo.Building }}</div></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
           <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500" style="margin-top:50px;">
            <p><span>Recruit Step</span></p>
            <h4>キャリア採用先行ステップ</h4>
          </div>
            <div class="mt30">
              <div class="jcl_recruit_step_body">
                <div class="jcl_recruit_step_body">
                  <div data-aos="fade-right">
                    <div class="jcl_recruit_step_title">STEP.1 応募</div>
                    <div class="jcl_recruit_step_content">応募まずは電話、メールでご連絡いただいた上で郵送またはメール送信で応募書類を提出してください。</div>
                  </div>
                  <div class="tc f20"><i class="el-icon-caret-bottom"></i></div>
                  <div data-aos="fade-right">
                    <div class="jcl_recruit_step_title">STEP.2 書類選考</div>
                    <div class="jcl_recruit_step_content">書類選考を行います。</div>
                  </div>
                  <div class="tc f20"><i class="el-icon-caret-bottom"></i></div>
                  <div data-aos="fade-right">
                    <div class="jcl_recruit_step_title">STEP.3 一次面接</div>
                    <div class="jcl_recruit_step_content">人物重視で選考を行います。どんなことでもお気軽にお話しください。</div>
                  </div>
                  <div class="tc f20"><i class="el-icon-caret-bottom"></i></div>
                  <div data-aos="fade-right">
                    <div class="jcl_recruit_step_title">STEP.4 条件面談 二次面談</div>
                    <div class="jcl_recruit_step_content">採用条件等の提示面談を実施します。現場担当者による詳細な技術内容の確認面談を行う場合もあります。</div>
                  </div>
                </div>
              </div>
            </div>
           <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500" style="margin-top:50px;">
            <p><span>Entry</span></p>
            <h4>エントリー</h4>
          </div>
            <div class="mt30">
              <el-row :gutter="24">
                <el-col :lg="12">
                  <div class="jcl_recruit_window_body" data-aos="fade-right">
                    <div class="jcl_recruit_window_title"><i class="el-icon-s-promotion"></i>フォームからの応募はこちらから</div>
                    <div class="jcl_recruit_window_content" data-aos="fade-right">
                      送信先：<a :href="'mailto:'+HomeInfo.Email">{{HomeInfo.Email}}</a><br />
                      携帯電話/Mail等のご連絡先をご記入ください<br />
                      不明な点がございましたらご連絡ください<br />
                      <a href="/contact">お問い合わせフォーム</a>
                    </div>
                  </div>
                </el-col>
                <el-col :lg="12">
                  <div class="jcl_recruit_window_body" data-aos="fade-right">
                    <div class="jcl_recruit_window_title"><i class="el-icon-phone"></i>お電話からの応募はこちらから</div>
                    <div class="jcl_recruit_window_content" data-aos="fade-right">
                      個人情報取扱いの苦情申し出先<br />
                      管理部（苦情相談窓口責任者）<br />
                      TEL：<a :href="'tel:'+HomeInfo.Tel">{{HomeInfo.Tel}}</a><br />
                      会社名称：{{HomeInfo.CompanyName}}<br />
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
  </section>
  <!-- End reinfo_contact section -->
</template>

<script>
import {onMounted, ref} from "vue";
import MailFrom from "../components/MailFrom";
import BreadCrumb from "../components/BreadCrumb";

export default {
  name: "Recruit",
  components:{ MailFrom,BreadCrumb},
  data() {
    return {
      active: 0
    }
  },
  setup(){
    const currentTab = ref('MailFrom')
    onMounted(() => {
      document.title = '採用情報 - JCL';
    })
    return {currentTab}
  },
}
</script>