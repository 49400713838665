<template>
  <div class="error-404__bubble-1"></div>
  <!-- /.error-404__bubble-1 -->
  <div class="error-404__bubble-2"></div>
  <!-- /.error-404__bubble-2 -->
  <div class="error-404__bubble-3"></div>
  <!-- /.error-404__bubble-3 -->
  <div class="error-404__bubble-4"></div>
  <!-- /.error-404__bubble-4 -->
  <div class="error-404__bubble-5"></div>
  <!-- /.error-404__bubble-5 -->
  <div class="error-404__bubble-6"></div>
  <!-- /.error-404__bubble-6 -->
  <div class="error-404__bubble-7"></div>
  <!-- /.error-404__bubble-7 -->
  <div class="error-404__bubble-8"></div>
  <!-- End reinfo_breadcrumb section -->

  <div class="error-404__bubble-8"></div>
  <div class="jcl_contact_body common_body">
    <div class="jcl_contact_text" data-aos="fade-right">
      <p>お客様の個人情報につきましては、他の目的に使用することはございません、弊社「個人情報保護規定」に基づいて責任をもって管理いたします。
      <br/>詳しくは弊社「<a href="/policy">プライバシーポリシー</a>」をご参照ください。</p>
      <p class="mt15">代表電話 <a :href="'tel:'+HomeInfo.Tel">{{HomeInfo.Tel}}</a></p>
      <p class="mt15">採用については<a href="/recruit">こちら</a></p>
    </div>
    <div>
      <div class="jcl_contact_step_body" data-aos="fade-right">
        <el-steps :active="step" simple v-if="ww>850">
          <el-step title="必要事項の入力" icon="el-icon-edit"></el-step>
          <el-step title="入力の内容確認" icon="el-icon-tickets"></el-step>
          <el-step title="送信完了" icon="el-icon-circle-check"></el-step>
        </el-steps>
        <el-steps :active="step" simple v-else>
          <el-step title="入力" icon="el-icon-edit"></el-step>
          <el-step title="確認" icon="el-icon-tickets"></el-step>
          <el-step title="完了" icon="el-icon-circle-check"></el-step>
        </el-steps>
      </div>
      <div>
        <div class="jcl_contact_form_body" v-show="step===0">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" :label-position="ww>850?'left':'top'">
            <el-form-item label="お問い合わせ種類" prop="typeContact" data-aos="fade-right" :data-aos-duration="500">
              <el-radio-group v-model="ruleForm.typeContact">
                <el-radio v-for="(item,index) in radioList()" :key="index" :label="index">{{ item.called }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="お名前" prop="name">
              <el-input data-aos="fade-right" :data-aos-duration="500" v-model="ruleForm.name"
                        placeholder="(例) JCL 太郎"></el-input>
            </el-form-item>
            <el-form-item label="お名前フリガナ" prop="nameFurigana">
              <el-input data-aos="fade-right" :data-aos-duration="500" v-model="ruleForm.nameFurigana"
                        placeholder="(例) JCL タロウ"></el-input>
            </el-form-item>
            <el-form-item label="会社名" prop="companyName">
              <el-input v-model="ruleForm.companyName" :data-aos-duration="500" data-aos="fade-right"
                        :placeholder="'(例) '+ HomeInfo.CompanyName"></el-input>
            </el-form-item>
            <el-form-item label="部署" prop="departmentName">
              <el-input v-model="ruleForm.departmentName" :data-aos-duration="500" data-aos="fade-right"
                        placeholder="(例) 営業部"></el-input>
            </el-form-item>
            <el-form-item label="役職" prop="positionName">
              <el-input v-model="ruleForm.positionName" :data-aos-duration="500" data-aos="fade-right"
                        placeholder="(例) 課長"></el-input>
            </el-form-item>
            <el-form-item label="住所（都道府県）" prop="address">
                <el-select v-model="ruleForm.address" :data-aos-duration="500" data-aos="fade-right"
                           placeholder="都道府県を選択してください">
                  <el-option
                      v-for="(value, key, index) in options"
                      :key="index"
                      :label="value"
                      :value="value">
                  </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="電話番号" class="is-required">
              <div class="jcl_contact_form_3" data-aos="fade-right" :data-aos-duration="500">
                <el-form-item prop="phone" class="phone_input_item">
                  <el-input type="tel" v-model="ruleForm.phone" @input="checkNumber('phone')" :maxlength="4"
                            placeholder="(例) 03"></el-input>
                </el-form-item>
                <span class="jcl_contact_form_-">-</span>
                <el-form-item prop="phone2" class="phone_input_item">
                  <el-input type="tel" v-model="ruleForm.phone2" @input="checkNumber('phone2')" :maxlength="4"
                            placeholder="(例) 5050"></el-input>
                </el-form-item>
                <span class="jcl_contact_form_-">-</span>
                <el-form-item prop="phone3" class="phone_input_item">
                  <el-input type="tel" v-model="ruleForm.phone3" @input="checkNumber('phone3')" :maxlength="4"
                            placeholder="(例) 2712"></el-input>
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item label="FAX" id="fax" prop="fax">
              <div class="jcl_contact_form_3" data-aos="fade-right" :data-aos-duration="500">
                <el-form-item prop="fax" class="phone_input_item">
                  <el-input type="tel" v-model="ruleForm.fax" @input="checkNumber('fax')" :maxlength="4"
                            placeholder="(例) 03"></el-input>
                </el-form-item>
                <span class="jcl_contact_form_-">-</span>
                <el-form-item prop="fax2" class="phone_input_item">
                  <el-input type="tel" v-model="ruleForm.fax2" @input="checkNumber('fax2')" :maxlength="4"
                            placeholder="(例) 5050"></el-input>
                </el-form-item>
                <span class="jcl_contact_form_-">-</span>
                <el-form-item prop="fax3" class="phone_input_item">
                  <el-input type="tel" v-model="ruleForm.fax3" @input="checkNumber('fax3')" :maxlength="4"
                            placeholder="(例) 2712"></el-input>
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item label="メールアドレス" prop="email">
              <el-input data-aos="fade-right" :data-aos-duration="500" v-model="ruleForm.email" placeholder="(例) service@jcltk.com"></el-input>
            </el-form-item>
            <el-form-item label="お問い合わせ内容" prop="content">
              <el-input data-aos="fade-right" :data-aos-duration="500" type="textarea" v-model="ruleForm.content" placeholder="お問い合わせ、ご相談内容などご自由に記入ください。" :rows="6"></el-input>
            </el-form-item>
            <el-form-item label-width="0px" prop="type" class="tc" data-aos="fade-right" :data-aos-duration="500">
              <div class="jcl_contact_form_agree_content_title">お問い合わせにおける個人情報の取り扱いについて
              </div>
              <el-collapse-transition data-aos="fade-right" :data-aos-duration="500">
                <div>
                  <div class="jcl_contact_form_agree_content">
                    <h5>
                        (１)	事業者の名称
                    </h5>
                    <p>{{HomeInfo.CompanyName}}</p>
                    <br />
                    <h5>
                      (２)	個人情報保護管理者の氏名又は職名、所属及び連絡先
                    </h5>
                    <p>
                        個人情報保護管理者：代表取締役　{{HomeInfo.Representative}}<br/>
                        メール：{{HomeInfo.Email}}<br/>
                        ＴＥＬ：{{HomeInfo.Tel}}<br/>
                    </p>
                    <br />
                    <h5>(３)	個人情報の利用目的</h5>
                    <p>お問い合わせ、資料請求に対応するするため。</p>
                    <br />
                    <h5>(４)	個人情報の第三者提供について</h5>
                    <p>
                      取得した個人情報は法令等による場合を除いて第三者に提供することはありません。
                    </p>
                    <br />
                    <h5>(５)	個人情報の取扱いの委託について</h5>
                    <p>取得した個人情報の取扱いの全部又は、一部を委託することがあります。委託に際しては、個人情報の取扱いについて当社水準と同等かそれ以上の安全を担保できる事業者を選定します。</p>
                    <br />
                    <h5>(６)	個人情報を与えなかった場合に生じる結果</h5>
                    <p>個人情報のご提供は任意です。ただし、お問い合わせに必要な個人情報をご提供いただけない場合は、お問い合わせ内容に回答出来ない可能性があります。</p>
                    <br /> 
                    <h5>(７)	開示対象個人情報の開示等および問い合わせ窓口について</h5>
                    <p>
                       ご本人からの請求により、当社が保有する開示対象個人情報に関する開示、利用目的の通知、内容の訂正・追加または削除、利用停止、消去および第三者提供の停止、第三者提供記録の開示に応じます。<br/>
                       ご連絡窓口は、下記「当社の個人情報の取扱いに関する苦情、相談等の問合せ先」を参照してください。
                    </p>
                    <br />
                    <h5>(８)	本人が容易に認識できない方法による個人情報の取得</h5>
                    <p>クッキーやウェブビーコン等を用いるなどして、本人が容易に認識できない方法による個人情報の取得は行っておりません。</p>
                    <br />
                    <h5>(９)	個人情報の安全管理措置について</h5>
                    <p>
                      取得した個人情報については、漏洩、減失またはき損の防止と是正、その他個人情報の安全管理のために必要かつ適切な措置を講じます。<br/>
                      お問い合わせおいて取得した個人情報は、当社内において保管期間を定め、保管期間経過後は削除又は破棄いたします。
                    </p>
                    <br />
                    <h5>(１０)	個人情報保護方針</h5>
                    <p>当社ホームページの個人情報保護方針をご覧下さい。</p>
                    <br />
                    <h5>(１１)	当社の個人情報の取扱いに関する苦情、相談等の問合せ先</h5>
                    <p>
                     【個人情報お問い合わせ窓口】<br/>
                      窓口責任者：個人情報保護管理者<br/>
                      住　所：〒{{HomeInfo.PostCode}} {{HomeInfo.Address}} {{ HomeInfo.Building }}<br/>
                      会　社：{{HomeInfo.CompanyName}}<br/>
                      ＴＥＬ：{{HomeInfo.Tel}}<br/>
                      メール：{{HomeInfo.Email}}
                    </p>
                  </div>
                </div>
              </el-collapse-transition>
            </el-form-item>
            <el-form-item label-width="0px" prop="type" class="tc is-required" data-aos="fade-right" :data-aos-duration="500">
              <el-checkbox v-model="ruleForm.agree">
                個人情報の取り扱いについて同意します
              </el-checkbox>
            </el-form-item>
            <el-form-item label-width="0px" prop="type" class="tc" data-aos="fade-right" :data-aos-duration="500">
              <div class="tc">
               <!-- <input type="button" class="thm-btn" @click="submitForm('ruleForm')" value=" 入力内容を確認する " /> -->
                <a href="javascript:void(0);" @click="submitForm('ruleForm')" class="octf-btn octf-btn-primary octf-btn-icon home_img_right" tabindex="-1"><span>&emsp;入力内容を確認する&emsp;<i class="flaticon-right-arrow-1"></i></span></a>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div v-show="step===1" class="jcl_contact_form_check_body">
          <table class="jcl_contact_form_check_table">
            <tbody>
             <tr data-aos="fade-right" :data-aos-duration="500">
              <td class="jcl_contact_form_check_title">お問い合わせ種類</td>
               <td class="jcl_contact_form_check_content">{{ radioList()[ruleForm.typeContact].called }}</td>
             </tr>
             <tr data-aos="fade-right" :data-aos-duration="500">
               <td class="jcl_contact_form_check_title">お名前</td>
               <td class="jcl_contact_form_check_content">{{ ruleForm.name }}</td>
             </tr>
             <tr data-aos="fade-right" :data-aos-duration="500">
               <td class="jcl_contact_form_check_title">お名前フリガナ</td>
               <td class="jcl_contact_form_check_content">{{ ruleForm.nameFurigana }}</td>
             </tr>
             <tr :data-aos-duration="500" data-aos="fade-right" v-if="ruleForm.companyName!=''">
               <td class="jcl_contact_form_check_title">会社名</td>
               <td class="jcl_contact_form_check_content">{{ ruleForm.companyName }}</td>
             </tr>
             <tr :data-aos-duration="500" data-aos="fade-right" v-if="ruleForm.departmentName!=''">
               <td class="jcl_contact_form_check_title">部署</td>
               <td class="jcl_contact_form_check_content">{{ ruleForm.departmentName }}</td>
             </tr>
             <tr :data-aos-duration="500" data-aos="fade-right" v-if="ruleForm.positionName!=''">
               <td class="jcl_contact_form_check_title">役職</td>
               <td class="jcl_contact_form_check_content">{{ ruleForm.positionName }}</td>
             </tr>
             <tr :data-aos-duration="500" data-aos="fade-right" v-if="ruleForm.address!=''">
               <td class="jcl_contact_form_check_title">住所（都道府県）</td>
               <td class="jcl_contact_form_check_content">{{ ruleForm.address }}</td>
             </tr>
             <tr data-aos="fade-right" :data-aos-duration="500">
               <td class="jcl_contact_form_check_title">電話番号</td>
               <td class="jcl_contact_form_check_content">
                 {{ ruleForm.phone + '-' + ruleForm.phone2 + '-' + ruleForm.phone3 }}
               </td>
             </tr>
             <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.fax!=''">
               <td class="jcl_contact_form_check_title">FAX</td>
               <td class="jcl_contact_form_check_content">
                 {{ ruleForm.fax + '-' + ruleForm.fax2 + '-' + ruleForm.fax3 }}
               </td>
             </tr>
             <tr data-aos="fade-right" :data-aos-duration="500">
               <td class="jcl_contact_form_check_title">メールアドレス</td>
              <td class="jcl_contact_form_check_content">{{ruleForm.email}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500">
              <td class="jcl_contact_form_check_title">お問い合わせ内容</td>
              <td class="jcl_contact_form_check_content type_content">{{ruleForm.content}}</td>
            </tr>
            </tbody>
          </table>
          <div class="tc" data-aos="fade-right" :data-aos-duration="500">
              <!-- <input type="button" class="thm-btn" @click="sendMail()" :loading="sending" value="　送信　" />&emsp;&emsp; -->
              <a href="javascript:void(0);" @click="sendMail()" class="octf-btn octf-btn-primary octf-btn-icon" tabindex="-1"><span>&emsp;送信&emsp;<i class="flaticon-right-arrow-1"></i></span></a>
              <!-- <input type="button" class="thm-btn" style="background-image:linear-gradient(90deg, #61d8ff 0%, #6168ff 51%, #61d8ff 100%)" @click="backform()" value="前画面へ戻る" /> -->
              &emsp;&emsp;
               <a href="javascript:void(0);" @click="backform()" class="octf-btn octf-btn-icon octf-btn-secondary" tabindex="-1"><span>前画面へ戻る<i class="flaticon-right-arrow-1"></i></span></a>
          </div>
        </div>
        <div v-show="step===2" class="jcl_contact_form_send_ok_body tc">
          <i class="el-icon-success jcl_contact_form_send_ok_ico"></i>
          <br />
          <p class="f18" data-aos="fade-right" :data-aos-duration="500">お問い合わせありがとうございました。<br />送信は正常に完了しました。</p>
          <div class="tc mt30" data-aos="fade-right" :data-aos-duration="500">
             <!-- <input type="button" @click="backtop()" class="thm-btn" style="background-image:linear-gradient(90deg, #61d8ff 0%, #6168ff 51%, #61d8ff 100%)"  value=" トップページへ戻る " /> -->
             <a href="javascript:void(0);" @click="backtop()" class="octf-btn octf-btn-icon octf-btn-secondary" tabindex="-1"><span>トップページへ戻る<i class="flaticon-right-arrow-1"></i></span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const typelist = [{called:'経営管理'},{called:'人事・給与'},{called:'セキュリティ'},{called:'システム運用'},{called:'業務支援'},{called:'その他'}];
const options = {
  '41043':'北海道',
  '41045':'青森県',
  '41047':'岩手県',
  '41049':'宮城県',
  '41051':'秋田県',
  '41053':'山形県',
  '41055':'福島県',
  '41057':'茨城県',
  '41059':'栃木県',
  '41061':'群馬県',
  '41063':'埼玉県',
  '41065':'千葉県',
  '41067':'東京都',
  '41069':'神奈川県',
  '41071':'新潟県',
  '41073':'富山県',
  '41075':'石川県',
  '41077':'福井県',
  '41079':'山梨県',
  '41081':'長野県',
  '41083':'岐阜県',
  '41085':'静岡県',
  '41087':'愛知県',
  '41089':'三重県',
  '41091':'滋賀県',
  '41093':'京都府',
  '41095':'大阪府',
  '41097':'兵庫県',
  '41099':'奈良県',
  '41101':'和歌山県',
  '41103':'鳥取県',
  '41105':'島根県',
  '41107':'岡山県',
  '41109':'広島県',
  '41111':'山口県',
  '41113':'徳島県',
  '41115':'香川県',
  '41117':'愛媛県',
  '41119':'高知県',
  '41121':'福岡県',
  '41123':'佐賀県',
  '41125':'長崎県',
  '41127':'熊本県',
  '41129':'大分県',
  '41131':'宮崎県',
  '41133':'鹿児島県',
  '41135':'沖縄県',
  '41137':'海外'
}

export default {
  name: "MailFrom",
  data() {
    return {
      step: 0,
      agree: false,
      options,
      ww: window.innerWidth,
      ruleForm: {
        typeContact: 0,
        name: '',
        nameFurigana: '',
        companyName: '',
        departmentName: '',
        positionName: '',
        address:'',
        phone: '',
        phone2: '',
        phone3: '',
        fax: '',
        fax2: '',
        fax3: '',
        email: '',
        content: '',
        agree: false
      },
      sending: false,
      rules: {
        name: [
          {
            required: true,
            message: 'お名前を入力してください',
            trigger: 'blur'
          }
        ],
        nameFurigana: [
          {
            pattern: /^[ァ-ー\s]+$/u,
            required: true,
            message: 'フリガナを入力してください',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            pattern: /^[0-9]{2,4}$/g,
            required: true,
            message: '番号を入力してください',
            trigger: 'change'
          }
        ],
        phone2: [
          {
            pattern: /^[0-9]{2,4}$/g,
            required: true,
            message: '番号を入力してください',
            trigger: 'change'
          }
        ],
        phone3: [
          {
            pattern: /^[0-9]{4}$/g,
            required: true,
            message: '番号を入力してください',
            trigger: 'change'
          }
        ],
        fax: [
          {
            pattern: /^[0-9]{2,4}$/g,
            required: false,
            message: '番号を入力してください',
            trigger: 'change'
          }
        ],
        fax2: [
          {
            pattern: /^[0-9]{2,4}$/g,
            required: false,
            message: '番号を入力してください',
            trigger: 'change'
          }
        ],
        fax3: [
          {
            pattern: /^[0-9]{4}$/g,
            required: false,
            message: '番号を入力してください',
            trigger: 'change'
          }
        ],
        email: [
          {
            type: 'email',
            required: true,
            message: 'メールアドレスを正しく入力してください',
            trigger: 'blur'
          }
        ],
        content: [
          {
            required: true,
            message: 'お問い合わせを正しく入力してください',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted() {
  },
  methods: {
    radioList() {
       return typelist;
    },
    checkNumber(name) {
      let text = this.ruleForm[name]
      this.ruleForm[name] = text.replace(/[^\d]/g, '')
    },
    backtop() {
      window.location.href = '/'
    },
    send() {
      this.step = 2
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
    backform() {
      this.step = 0
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (!this.ruleForm.agree) {
            this.$message({
              message:
                  '「個人情報の取り扱いについて同意します」をチェックしてください。',
              type: 'warning'
            })
            return false
          }
          this.step = 1
          document.body.scrollTop = document.documentElement.scrollTop = 0
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    sendMail() {
      let params = {
        name: this.ruleForm.name,
        nameFurigana: this.ruleForm.nameFurigana,
        companyName: this.ruleForm.companyName,
        departmentName: this.ruleForm.departmentName,
        positionName:this.ruleForm.positionName,
        address:this.ruleForm.address,
        phone: this.ruleForm.phone +'-'+ this.ruleForm.phone2  +'-'+ this.ruleForm.phone3,
        fax: this.ruleForm.fax + this.ruleForm.fax2 + this.ruleForm.fax3,
        email: this.ruleForm.email,
        content: this.ruleForm.content.replace(/\r?\n/g, '<br>'),
        typeContact: typelist[this.ruleForm.typeContact].called
      }
      this.sending = true,
          $.ajax({
            type: "POST",
            url: "/api/mail.php",
            dataType: "json",
            data: params,
            success: (res) => {
              debugger
              this.sending = false
              if (!res) {
                this.$message({
                  message:
                      'ただいまメンテナンス中です。ご迷惑をお掛け致しますが、しばらくお待ち下さい。。',
                  type: 'warning'
                })
                return false
              }
              if (res.code === 1) {
                this.send()
              } else {
                this.$message({
                  message:
                      'ただいまメンテナンス中です。ご迷惑をお掛け致しますが、しばらくお待ち下さい。。',
                  type: 'warning'
                })
              }
            },
            error:(res,aaa,bbb)=> {
              alert(aaa)
               this.$message({
                  message:
                      'ただいまメンテナンス中です。ご迷惑をお掛け致しますが、しばらくお待ち下さい。。',
                  type: 'warning'
                })
            }
          });
    }
  }
}
</script>
